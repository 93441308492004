<template>
  <div class="d-flex justify-center mb-6">
    <v-card elevation="0" style="width: 98%;">
      <!-- Cards -->
      <v-container fluid>
        <v-toolbar flat class="mb-4">
          <v-text-field
            style="width: 100%; max-width: 450px"
            v-model="searchTerm"
            append-icon="search"
            ref="searchArticle"
            label=""
            :disabled="loading"
            single-line
            clearable
            hide-details
            @click:clear="viewAll"
            @click:append="() => onChange(searchTerm)"
            @change="(term) => onChange(term)"
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-select
            :disabled="loading"
            @input="updateSortAndOrder"
            style="height: 40px; min-height: 44px; max-width: 105px;"
            dense
            label="الترتيب حسب"
            v-model="sortByDropdown"
            :items="sortDropdown"
            filled
            class="ml-4"
            hide-selected
            return-object
            outlined
          ></v-select>
          <v-select
            :disabled="loading"
            @input="updateSortAndOrder"
            style="height: 40px;min-height: 44px;max-width: 105px;"
            dense
            class="ml-4"
            label="التسلسل"
            v-model="orderDropdown"
            :items="[
              { text: 'تصاعدي', field: 'ASC' },
              { text: 'تنازلي', field: 'DESC' },
            ]"
            filled
            hide-selected
            return-object
            outlined
          ></v-select>
        </v-toolbar>
        <div
          v-if="loading"
          class="d-flex h-100 justify-center align-items-center"
          style="min-height:50vh;"
        >
          <v-progress-circular
            indeterminate
            color="black"
            size="50"
          ></v-progress-circular>
        </div>
        <v-data-iterator
          v-else
          :items="getSearchResultArticles"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="article in props.items"
                :key="article.id"
                cols="12"
                sm="4"
                md="4"
                lg="4"
              >
                <v-card>
                  <div class="article-image-container">
                    <img
                      class="h-150 article-image"
                      :src="
                        apiUrl +
                          '/entities/images/raw/' +
                          (article.newPdfPath && article.newPdfPath.length > 0
                            ? article.newPdfPath
                            : article.oldPdfPath) +
                          '/' +
                          articlesSize
                      "
                    />
                  </div>

                  <div style="height: 100px; max-height: 70px;">
                    <v-card-title
                      class="subheading font-weight-bold"
                      style="direction: rtl; text-align: right;"
                    >
                      {{ truncate(article.title, 80) }}
                    </v-card-title>
                  </div>

                  <v-divider
                    style="margin-bottom: 0px; margin-top: 10px;"
                  ></v-divider>

                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[0].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ article.id }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[2].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ article.safirUniqueId }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[4].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ article.authorName }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[6].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ article.sourceName }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider
                    style="margin-bottom: 2px; margin-top: 0px;"
                  ></v-divider>

                  <div class="d-flex flex-direction-row justify-space-around">
                    <v-btn plain @click="goToEdit(article.id)">
                      <v-icon>
                        mdi-archive-edit
                      </v-icon>
                    </v-btn>
                    <v-btn plain @click="deleteArticleSingle(article.id)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>

      <div class="d-flex justify-content-center w-100 mt-4">
        <v-pagination
          v-model="currentPage"
          color="#f29100ff"
          :length="totalPages"
          :total-visible="6"
          @input="handlePageChange"
        >
        </v-pagination>
        <v-select
          :items="[6, 18, 24, 54, 75]"
          v-model="query.pageSize"
          dense
          flat
          :loading="loading"
          label="عدد"
          class="mr-2"
          @change="updateSortAndOrder"
          style="max-width: 55px; max-height: 50px;"
        ></v-select>
      </div>
      <div v-if="!loading" class="d-flex justify-content-center mt-4">
        {{ currentPageCountStart }} -
        {{ finalNumberText }}
        من أصل {{ getArticlesGlobalCount }}
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import articleConfig from "@helpers/articleStructure";
import getSortableField from "@helpers/getSortableField";
import config from "@/helpers/config";

export default {
  data() {
    return {
      title: articleConfig.title,
      properties: articleConfig.properties,
      singleSelect: false,
      searchTerm: "",
      selected: [],
      loading: false,
      sortByDropdown: { text: "#UID", field: "_id" },
      orderDropdown: { text: "تصاعدي", field: "ASC" },
      sortDropdown: articleConfig.getSortDropdown(),
      query: {
        order: "ASC",
        sortBy: "id",
        pageSize: 6,
        pageNumber: 1,
      },
      currentPageCountStart: 1,
      currentPageCountEnd: 1,
      currentPage: 1,
      totalPages: 0,
      apiUrl: config.apiUrl,
      articlesSize: "400",
    };
  },
  methods: {
    ...mapActions("articlesBackend", [
      "fetchSearchResultArticles",
      "fetchAllArticles",
      "deleteArticle",
    ]),
    ...mapMutations("notification", { notifyVuex: "notify" }),
    ...mapMutations("articlesBackend", ["SET_NUMBER_OF_ROWS"]),
    setLoading(status) {
      this.loading = status;
      this.updatePagination();
    },
    goToEdit(id) {
      if (!this.loading) {
        this.$router.push({ name: "ArticleEdit", params: { id } });
      }
    },
    viewAll() {
      this.setQuery();
      this.fetchAllArticles({
        query: this.query,
        loadingFunction: this.setLoading,
      });
      this.setLoading(true);
      this.searchTerm = "";
    },
    notify(message) {
      this.notifyVuex({ show: true, message });
    },
    updatePagination() {
      this.totalPages = Math.ceil(
        this.getArticlesGlobalCount / this.query.pageSize
      );
      this.currentPageCountStart =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        1;
      this.currentPageCountEnd =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        parseInt(this.query.pageSize);
    },
    search(term) {
      this.setQuery();
      if (term) {
        this.fetchSearchResultArticles({
          term,
          query: { ...this.query },
          loadingFunction: this.setLoading,
        });
      } else {
        this.viewAll();
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.query.pageNumber = value;

      this.updateSortAndOrder();
      window.scrollTo(0, 0);
    },
    deleteArticleSingle(articleId) {
      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteArticle({
              articleId,
              loadingFunction: this.setLoading,
              inCaseOfError: this.notify,
            });
          }
        },
      });
    },
    handleClick(row) {
      if (!this.loading) {
        this.$router.push({ name: "ArticleEdit", params: { id: row.id } });
      }
    },
    goToCreate() {
      this.$router.push({ name: "ArticleCreate" });
    },
    onChange(term) {
      this.search(term);
      this.$refs.searchArticle.blur();
    },
    setQuery() {
      this.query.sortBy = getSortableField(this.sortByDropdown.field);
      this.query.order = this.orderDropdown.field;
    },
    updateSortAndOrder() {
      this.SET_NUMBER_OF_ROWS(this.query.pageSize);
      if (this.getSearchTermArticle) {
        this.search(this.searchTerm);
      } else {
        this.viewAll();
      }
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
  },
  computed: {
    ...mapGetters("articlesBackend", [
      "getSearchResultArticles",
      "getSearchTermArticle",
      "getArticlesGlobalCount",
      "getArticlesLocalCount",
      "getNumberOfRows",
    ]),
    finalNumberText: function() {
      if (this.currentPageCountEnd > this.getArticlesGlobalCount) {
        return this.getArticlesGlobalCount;
      } else {
        return this.currentPageCountEnd;
      }
    },
  },
  mounted() {
    this.query.pageSize = this.getNumberOfRows;
    this.currentPage = 1;
    if (!this.getSearchTermArticle) {
      this.viewAll();
    }
    this.searchTerm = this.getSearchTermArticle;
  },
};
</script>

<style>
th.text-right > span {
  font-size: 19px;
}

td.text-right {
  font-size: 16px !important;
}

.v-label {
  margin-top: 6px;
}

.v-select__slot {
  height: 44px;
}

div.v-select__slot:nth-child(1) {
  max-height: 35px;
  flex-direction: row-reverse;
}

div.v-select__selections:nth-child(1) {
  max-height: 30px;
}

div.v-select__slot:nth-child(1) > div:nth-child(2) {
  align-self: center;
}

div.v-select__slot:nth-child(1) > label:nth-child(1) {
  right: 8px !important;
}

.article-image-container {
  object-fit: cover;
}

.article-image {
  object-fit: cover;
  max-height: 250px;
  width: 100%;
}
</style>
