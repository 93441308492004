export default {
  title: "المقالات",
  entityName: "article",
  properties: [
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "عنوان",
      value: "title",
      align: "right",
      field: "_title",
      sortable: false,
    },
    {
      text: "معرف السفير فريد",
      value: "safirUniqueId",
      align: "right",
      field: "_safirUniqueId",
      sortable: false,
    },
    {
      text: "#UID المؤلف",
      value: "authorId",
      align: "right",
      field: "_authorId",
      sortable: false,
    },
    {
      text: "اسم المؤلف",
      value: "authorName",
      align: "right",
      field: "_authorName",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "#UID مصدر",
      value: "sourceId",
      align: "right",
      field: "_sourceId",
      sortable: false,
    },
    {
      text: "اسم المصدر",
      value: "sourceName",
      align: "right",
      field: "_sourceName",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "",
      value: "newPdfPath",
      align: "right",
      field: "_newPdfPath",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "",
      value: "oldPdfPath",
      align: "right",
      field: "_oldPdfPath",
      sortable: false,
      restrictSort: true,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field, restrictSort }) => {
      if (!restrictSort) {
        newArr.push({ text, field });
      }
    });

    return newArr.slice(0, 7);
  },
};
